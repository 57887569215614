import React from 'react';
import Layout from '../components/layout/Layout';

const Section: React.FC<{ title: string }> = props => (
  <div className="mb-12">
    <h2 className="mb-3 text-lg text-center border-b lg:text-left">
      {props.title}
    </h2>
    <p className="prose-sm text-gray-700">{props.children}</p>
  </div>
);

export default () => (
  <Layout>
    <div className="container mx-auto my-6 lg:my-12">
      <h1 className="mb-6 text-4xl font-semibold text-center lg:text-left">
        Aviso de Privacidad
      </h1>
      <Section title="Datos del Responsable">
        <p>
          Document Imaging Company S.A. de C.V. (en lo sucesivo el
          “Responsable”) es el responsable de recabar sus datos personales, del
          uso que se le dé a los mismos y su protección, con domicilio en Calle
          Colima No. 220 Int. 103 Col. Roma, Delegación Cuauhtémoc, Código
          Postal 06700 México, CDMX. Cómo contactarnos: Domicilio: Calle Colima
          No. 220 Int. 103 Col. Roma, Delegación Cuauhtémoc, Código Postal 06700
          México, CDMX. Correo electrónico: privacidad@document.com.mx Teléfono:
          01 (55) 5525 . 8882
        </p>
      </Section>
      <Section title="Datos personales">
        <p>
          El Responsable recabará de usted los datos personales ("Datos
          Personales") que sean necesarios para la adecuada prestación de sus
          servicios ("Servicios") ya sea (i) directamente, esto es a través de
          medios electrónicos, ópticos, sonoros, visuales, o cualquier otra
          tecnología, como correo postal, internet o vía telefónica; (ii)
          indirectamente, a través de otras fuentes que se encuentran permitidas
          por la Ley, esto a través de una fuente de acceso público o una
          transferencia; o (iii) personalmente a través de sus agentes o
          promotores cuando usted mismo los proporciona con la presencia física
          de ambos. Dichos Datos Personales podrán incluir todos o algunos de
          los siguientes: Datos de identificación: nombre completo, dirección,
          teléfono de casa, celular y/o de trabajo, sexo, nacionalidad, fecha de
          nacimiento, ocupación o profesión, estado civil, Registro Federal de
          Contribuyentes (RFC), Clave Única de Registro Poblacional (CURP). El
          Responsable no recabará ni tratará datos personales sensibles.
        </p>
      </Section>
      <Section title="Finalidades del Tratamiento de los Datos Personales">
        <p className="mb-1">
          El Responsable tratará sus Datos Personales única y exclusivamente
          conforme a los términos del presente Aviso de Privacidad. Los Datos
          Personales que nos ha proporcionado serán utilizados para las
          siguientes finalidades necesarias y que dan origen a la relación
          contractual entre el Responsable y usted como Titular de los datos
          personales:
        </p>
        <ul className="space-y-1 list-disc list-outside">
          <li className="list-inside">
            Proveer los servicios y productos requeridos por usted.
          </li>
          <li className="list-inside">
            Informar sobre cambios o servicios que estén relacionados con el
            contratado o adquirido por el cliente; Cumplir con nuestras
            obligaciones profesionales.
          </li>
          <li className="list-inside">
            Cumplir eficaz y profesionalmente con los compromisos adquiridos de
            nuestros servicios de contabilidad, auditoría, revisión de
            impuestos, consultoría, servicios integrados solicitados.
          </li>
          <li className="list-inside">Evaluar la calidad del servicio.</li>
          <li className="list-inside">
            Realizar estudios internos sobre hábitos de consumo.
          </li>
        </ul>
      </Section>
      <Section title="Transferencia de Datos Personales">
        <p>
          El Responsable requiere transferir sus Datos Personales en la medida
          que sea necesario para la prestación de los Servicios dentro e incluso
          fuera de la República Mexicana. El Responsable se compromete a velar
          porque se cumplan los principios legales de protección en torno a la
          transferencia de datos personales. De igual forma, manifiesta su
          compromiso para que se respete en todo momento, por nosotros y por
          cualquiera de las sociedades que forman parte del grupo corporativo
          del Responsable, el presente Aviso de Privacidad. Nos comprometemos a
          no transferir su información personal a terceros sin su
          consentimiento, salvo las excepciones previstas en el artículo 37 de
          la Ley Federal de Protección de Datos Personales en Posesión de
          Particulares, así como a realizar esta transferencia en los términos
          que fija esa ley.
        </p>
      </Section>
      <Section title="Limitación de Uso o Divulgación de los Datos Personales">
        <p>
          Usted puede limitar el uso o divulgación de sus datos para dejar de
          recibir promociones, mensajes, ofertas y publicidad respecto de los
          productos y servicios del Responsable llamando al teléfono de la
          Unidad Especializada de Atención.
        </p>
      </Section>
      <Section title="Medios para ejercer los Derechos ARCO">
        <p>
          Conforme a la Ley Federal de Protección de Datos Personales en
          Posesión de los Particulares y su Reglamento, usted o mediante
          representante legal, tiene el derecho de acceder a sus datos
          personales que poseemos y a los detalles del tratamiento de los
          mismos, así como a rectificarlos en caso de ser inexactos o
          incompletos; cancelarlos cuando resulten ser excesivos o innecesarios
          para las finalidades que justificaron su obtención u oponerse al
          tratamiento de los mismos para fines específicos. El mecanismo que se
          ha implementado para el ejercicio de dichos derechos es a través de la
          presentación de la Solicitud de Ejercicio de Derechos ARCO (la
          “Solicitud”), misma que ponemos a su disposición al final del presente
          Aviso de Privacidad, esta deberá ser descargada, requisitada y firmada
          por el Titular o su representante legal y enviarla al correo
          electrónico privacidad@document.com.mx con la documentación señalada
          en ella, así mismo se le comunicará la fecha de recepción de la misma
          vía correo electrónico. Por último, le informamos que se le responderá
          en un plazo máximo de 20 (veinte) días hábiles, contados desde la
          fecha de recepción por parte del Responsable siempre que cumpla con
          todos los requisitos señalados en la Solicitud, a efecto de que, si
          resulta procedente, se haga efectivo el derecho ARCO solicitado dentro
          de los 15 (quince) días hábiles siguientes a la fecha en que se le
          comunique la respuesta.
        </p>
      </Section>
      <Section title="Medios para la Revocación del Consentimiento">
        <p>
          En cualquier momento del tratamiento usted podrá revocar el
          consentimiento que ha otorgado para el tratamiento de sus datos, para
          ello es necesario que envíe un correo electrónico al Departamento de
          Datos Personales solicitando la revocación de su consentimiento
          estando sujeto al procedimiento descrito anteriormente.
          <br />
          Le pedimos considerar que no podrá revocar el consentimiento si desea
          que subsista la relación contractual con el Responsable.
        </p>
      </Section>
      <Section title="Uso de cookies">
        <p>
          Los diferentes sitios de Internet del Responsable se basan en el
          manejo de Cookies y/o Web Beacons que permiten que el Responsable
          recabe automáticamente datos personales. Al ingresar y continuar con
          el uso del Sitio de Internet, usted consiente que el Responsable
          recabe y trate sus datos personales. El uso de estas tecnologías es
          necesario por motivos de operación, técnicos y de seguridad.
        </p>
      </Section>
      <Section title="Cambios al Aviso de Privacidad">
        <p>
          Nos reservamos el derecho de efectuar en cualquier momento
          modificaciones o actualizaciones al presente aviso de privacidad, para
          la atención de novedades legislativas o jurisprudenciales, políticas
          internas, nuevos requerimientos para la prestación u ofrecimiento de
          nuestros servicios o productos y prácticas del mercado.
          <br />
          Estas modificaciones serán comunicadas mediante los siguientes medios:
          (i) anuncios visibles en nuestras oficinas o centros de trabajo; (ii)
          en nuestra página de Internet www.document.com.mx; (iii) o se las
          haremos llegar al último correo electrónico que nos haya
          proporcionado.
        </p>
      </Section>
    </div>
  </Layout>
);
